.custom-form_layout {
    height: 42rem;
}

[fynd-form="fields"] {
    margin-bottom: 0rem;
}

[fynd-form="pagination"] {
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    background: #DEDEDE;
}

[fynd-form="pagination"] span {
    background: #000000 !important;
    border-radius: 100px;
}


select {
    color: #B5B5B5;
  }

  select:valid {
    color: black; 
  }

[aria-invalid="true"] {
    border: 1px solid #F50031;
}
[aria-invalid="false"] {
    border: 1px solid #E0E0E0;
}

.form-input {
    position: relative;
    z-index: 10;
}
span.fynd-form-error {
    position: absolute;
    bottom: -24px;
    left: 0;
    font-size: var(--sizes--12);
}
.swiper.form-slider.swiper-fade.swiper-initialized.swiper-horizontal.swiper-watch-progress.swiper-backface-hidden {
    overflow: visible;
}
.iti__country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .05);
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 0.5rem;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 10px;
    -webkit-overflow-scrolling: touch;
}
ul#iti-0__country-listbox {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .05);
    border: 1px solid #E0E0E0;
    margin-top: 10px;
}